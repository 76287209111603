.container{
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
  flex-direction: column;

}

.sub-text{
  margin-top: 4px;
  color: rgba(0,0,0,0.5);
  margin-bottom: 16px;
  text-align: center;
  font-size: 12px;
}

.text{
  font-size: 18px;
  font-weight: 500;
}
.lds-dual-ring {
  display: inline-block;

}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 8px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-color: #FF8C00 transparent #FF8C00 transparent;
  animation: lds-dual-ring 0.8s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
